import React from 'react';
import { graphql, Link } from 'gatsby';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../components/layout';
import LastPosts from '../components/lastPosts';
import SEO from '../components/seo';

const BlogList = ({ data, pageContext, location }) => {
  const posts = data.allMarkdownRemark.edges;
  const siteTitle = data.site.siteMetadata.title;
  const { numPages, currentPage } = pageContext;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Todos los avisos" />
      <LastPosts posts={posts} />
      <div className="mx-auto max-w-6xl">
        <hr />
        <div
          className={`flex px-3 py-4 ${
            currentPage < numPages && 1 < currentPage
              ? 'justify-between'
              : 1 < currentPage
              ? 'justify-end'
              : 'justify-start'
          }`}
        >
          {currentPage < numPages && (
            <Link className="no-underline flex items-center" to={`/advisories/pag-${currentPage + 1}`}>
              <FontAwesomeIcon className="text-xs mr-2" icon={faArrowLeft} /> Anteriores
            </Link>
          )}
          {1 < currentPage && (
            <Link className="no-underline" to={`/advisories/${currentPage - 1 > 1 ? `pag-${currentPage - 1}` : ''}`}>
              Nuevos <FontAwesomeIcon className="text-xs ml-2" icon={faArrowRight} />
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default BlogList;

export const pageQuery = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { index: { eq: null }, private: { eq: false } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            description
            tags
            severity
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
